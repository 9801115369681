<template>
  <main>
    <section class="horizon">
      <div class="container">
        <div class="in-between">
          <h2 class="horizon__title">Fuentes de tráfico</h2>
        </div>
        <div class="row row-table">
          <table-data
            title="Fuentes de tráfico"
            v-bind:source="mongo + '/' + db + '/'"
            metodo="analytics"
            name="source"
            dimensionsTag="source"
            tableTemplate="TableFuenteTrafico"
          />
        </div>
        <div class="row row-table">
          <table-data
            title="Fuentes de tráfico"
            v-bind:source="mongo + '/' + db + '/'"
            metodo="analytics"
            name="sourceMedium"
            dimensionsTag="sourceMedium"
            tableTemplate="TableFuenteTraficoMedio"
          />
        </div>
        <div class="row row-table">
          <table-data
            title="Páginas de destino"
            v-bind:source="mongo + '/' + db + '/'"
            metodo="analytics"
            name="landingPagePath"
            dimensionsTag="landingPagePath"
            tableTemplate="TablePaginasDestino"
          />
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import TableData from '@/components/TableData.vue'

export default {
  name: 'Conversiones',
  components: {
    'table-data': TableData,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const analytics = computed(() => store.getters.getAnalyticsWPJSONURL)
    const db = computed(() => store.getters.getDBDashboard)
    return { mongo, analytics, db }
  },
}
</script>
